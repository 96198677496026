import React, {useEffect, useRef, useState} from 'react'
import {Box, Button, IconButton, styled, Typography} from "@mui/material";
import {Loader} from "../../shared/Loader";
import {ScreenLayout} from "../../shared/ScreenLayout";

import {
    getAcademyItemThunk, FeedItem, TrainingVideoPage, clearPosts
} from "../../features/academyContent/academyContentSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {useNavigate, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {routes} from "../../constants/routes";
import {HLSVideoPlayer} from "../../shared/HLSVideoPlayer";
import {Analytics} from "../../lib/analytics";
import {useVideoDimensions} from "../../hooks/useVideoDimensions";
import DynamicTopBarColor from '../../DynamicTopBarColor';
import {useTheme} from "@mui/material/styles";
import {setCurrentPostPath} from "../../util/storage";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { 
    generateVirtualCoachPrompt, generateVirtualCoachOpeningLines 
} from "../../services/api";
import {CSSTransition} from "react-transition-group";
import {Links} from "../../constants/links";


const StyledBackButton = styled(Button)(() => ({
    fontSize: "32px",
    fontWeight: "700",
    margin: "0",
    padding: "10px",
    minWidth: "5px"
}));

const ChatBubbleLeft = styled(Box)(({ theme }) => ({
    // padding: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "10px",
    borderBottomLeftRadius: 0,
    marginBottom: "10px",
    textAlign: "left",
    display: "inline-block",
    maxWidth: "70%",
    wordWrap: "break-word",
    marginRight: "auto",
}));

const ChatBubbleText = styled(Typography)(() => ({
    padding: "10px"
}));


const ChatBubbleRight = styled(Box)(({ theme }) => ({
    maxWidth: "70%",
    backgroundColor: theme.palette.grey[300],
    // padding: "10px",
    borderRadius: "10px",
    borderBottomRightRadius: 0,
    marginBottom: "10px",
    textAlign: "left",
    marginLeft: "auto",
}));

// const ChatContainer = styled(Box)(() => ({
//     display: "flex",
//     flexDirection: "column",
//     padding: "16px",
//     flexGrow: 1,
//     overflowY: "auto"
// }));

const Avatar = styled("span")(() => ({
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    backgroundSize: "cover", // Ensures the image covers the entire span
    backgroundPosition: "center", // Centers the image
    backgroundRepeat: "no-repeat", // Ensures the image doesn't repeat
}))

const CoachAvatar = styled(Avatar)(({theme}) => ({
    backgroundImage: 'url("' + theme.custom.brandTitleLogo + '")',
    margin: "0 8px 0 10px"
}))


const InputContainer = styled(Box)(() => ({
    width: "100%",
    padding: "10px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto"
}));

const InputField = styled(Box)(() => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "25px",
    padding: "5px 10px"
}));

export const TrainingPost = (): React.ReactElement => {
    const [isInAiCoachPreRelease, setIsInAiCoachPreRelease] = useState(false);
    const navigate = useNavigate()
    const theme = useTheme();
    const params = useParams();
    const postId = params.pageId ? params.pageId : ""

    const dispatch = useAppDispatch()

    const postContent = useAppSelector<FeedItem|undefined>((state: RootState) => state.academyContent.trainingItem);
    const fetchingItem = useAppSelector<boolean>((state: RootState) => state.academyContent.fetchingTrainingItem);

    const post = postContent as TrainingVideoPage

    const {dimensions, error } = useVideoDimensions(post?.media?.video?.url || "")

    const [userPrompt, setUserPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState('');
    const [showCoachIntro, setShowCoachIntro] = useState<boolean>(true)

    const [animateQuestion, setAnimateQuestion] = useState<boolean>(false)
    const [animateLoading, setAnimateLoading] = useState<boolean>(false)
    const [animateResponse, setAnimateResponse] = useState<boolean>(false)

    const [activateScrollToBottom, setActivateScrollToBottom] = useState<boolean>(false)
    const bottomBoxRef = useRef<HTMLDivElement>(null)

    const [introMessage1, setIntroMessage1] = useState('');
    const [introMessage2, setIntroMessage2] = useState('');
    const [exampleQuestion, setExampleQuestion] = useState('');

    const [loadingFirstMessage, setLoadingFirstMessage] = useState(true);
    const [showFirstMessage, setShowFirstMessage] = useState(false);
    const [loadingSecondMessage, setLoadingSecondMessage] = useState(false);
    const [showSecondMessage, setShowSecondMessage] = useState(false);



    useEffect(()=>{
            console.log(bottomBoxRef, activateScrollToBottom)
            if(bottomBoxRef.current && activateScrollToBottom){
                console.log("refs exist")
                bottomBoxRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
                setActivateScrollToBottom(false)
            }
    },[bottomBoxRef, activateScrollToBottom])

    useEffect(() => {
        dispatch(clearPosts())
    }, [dispatch]);

    useEffect(()=>{
        console.log(dimensions)
        console.log("width:", dimensions?.width, "height:", dimensions?.height)
        console.log(error)
    },[dimensions, error])

    useEffect(() => {
        checkUserGroup()
        if(postId)
            dispatch(getAcademyItemThunk(postId))
    },[postId, dispatch])

    useEffect(() => {
        Analytics.trainingVideoView((postId));
        setCurrentPostPath(routes.trainingVideo.replace(":pageId",postId))
    },[postId])

    const checkUserGroup = async () => {
        setIsInAiCoachPreRelease(true);
    };    

    const sendQuestionToAPI = async (questionText:string) => {
        try {
            setLoading(true); 
            const apiResponse = await generateVirtualCoachPrompt(
                questionText,
                theme.custom.name,
                postId,
                post.tags[0].toLowerCase().replace(/\s+/g, '_')
              );
            setResponse(apiResponse);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching response from API: ', error);
            setLoading(false);
        }
    };

    useEffect(()=>{
        setAnimateQuestion(!!question)
    },[question])

    useEffect(()=>{
        if(loading){
            setTimeout(()=>{
                setAnimateLoading(loading)
            },400)
        }else{
            setAnimateLoading(false)
        }
    },[loading])

    useEffect(()=>{
        setAnimateResponse(!!response)
    },[response])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const questionToSubmit = userPrompt.trim() === '' ? exampleQuestion : userPrompt;
        setQuestion(questionToSubmit);
        sendQuestionToAPI(questionToSubmit);
        setShowCoachIntro(false)
        setUserPrompt('');
    };

    // const regex = />([^<:]+:)</g;

    useEffect(() => {
        const fetchOpeningLines = async () => {
            try {
                setLoadingFirstMessage(true);
                const openingLinesResponse = await generateVirtualCoachOpeningLines(
                    theme.custom.name,
                    postId,
                    post.tags[0].toLowerCase().replace(/\s+/g, '_')
                );
                
                setIntroMessage1(openingLinesResponse.intro_message1);
                setIntroMessage2(openingLinesResponse.intro_message2);
                setExampleQuestion(openingLinesResponse.example_question);
    
                setLoadingFirstMessage(false);
                setShowFirstMessage(true);
    
                setLoadingSecondMessage(true);
                setTimeout(() => {
                    setLoadingSecondMessage(false);
                    setShowSecondMessage(true);
                }, 2000);
    
            } catch (error) {
                console.error('Error fetching virtual coach opening lines: ', error);
            }
        };
    
        fetchOpeningLines();
    }, [postId, post, theme.custom.name]);

    return (
        <>
            <DynamicTopBarColor color={theme.custom.mainBackgroundColor}/>
            <Box sx={{display: "flex", flex: 1}}>
                <ScreenLayout
                    title={<StyledBackButton onClick={()=> navigate(postContent ? routes.train + "#item-" + postContent.id : routes.train)}><KeyboardBackspaceIcon/></StyledBackButton>}
                    ctaTitle={""}
                    onAction={()=>{}}
                    displayCta={false}
                    contentMarginTop={"0"}
                    contentPadding={"0"}
                    headerMarginTop={"0"}
                    headerPadding={"0"}
                    contentFeedBackgroundColor="#FFFFFF"
                ><>
                    {
                        (!(postContent === undefined) || fetchingItem)
                            && post
                            && post.media
                            && post.media.video
                            // && dimensions
                        ?
                            <Box>
                                <HLSVideoPlayer
                                    hlsSrc={!!post.media ? post.media.video.stream.url : ""}
                                    mp4Src={!!post.media ? post.media.video.url : ""}
                                    surl={!!post.media ? post.media.surl : undefined}
                                    videoPageId={post.id}
                                    controls
                                    width="100%"
                                    height={dimensions && dimensions.height >= dimensions.width ? "250px" : undefined}
                                    // src={!!post.media ? post.media.video.url : ""}
                                    autoPlay
                                    playsInline
                                    webkit-playsinline="true"
                                />
                                {/*<Box sx={{padding: "8px 5px"}}>*/}
                                {/*    <IconButton aria-label="like" sx={{flexShrink: 0, borderRadius: "14px"}} onClick={() => {*/}
                                {/*        dispatch(setSendingLikeTo(post.id))*/}
                                {/*        dispatch(likeItemThunk(post.id))*/}
                                {/*        setLikeSent(!likeSent)*/}
                                {/*    }}  >*/}
                                {/*        { liked ?*/}
                                {/*            <FavoriteIcon/>*/}
                                {/*            :*/}
                                {/*            <FavoriteBorderIcon/>*/}
                                {/*        }*/}
                                {/*        {(post.reactions.count + likeCounted) > 0 && (post.reactions.count + likeCounted) }*/}
                                {/*    </IconButton>*/}
                                {/*</Box>*/}
                                <Box sx={{
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    paddingLeft: '12px',
                                    paddingRight: '12px'
                                }}>
                                </Box>
                                <Box>
                                    {(isInAiCoachPreRelease) ? (
                                        <>
                                        <Box sx={{ flexGrow: 1, overflowY: "auto", display: "flex", flexDirection: "column", height: "45vh" }}>
                                        {showCoachIntro &&
                                            <>
                                                {loadingFirstMessage && (
                                                    <ChatMsg sender="Coach">
                                                        <ChatBubbleText>...</ChatBubbleText>
                                                    </ChatMsg>
                                                )}
                                                {showFirstMessage && (
                                                    <ChatMsg sender="Coach">
                                                        <ChatBubbleText>{introMessage1}</ChatBubbleText>
                                                    </ChatMsg>
                                                )}
                                                {loadingSecondMessage && (
                                                    <ChatMsg sender="Coach">
                                                        <ChatBubbleText>...</ChatBubbleText>
                                                    </ChatMsg>
                                                )}
                                                {showSecondMessage && (
                                                    <ChatMsg sender="Coach">
                                                        <ChatBubbleText>{introMessage2}</ChatBubbleText>
                                                    </ChatMsg>
                                                )}
                                            </>
                                        }
                                            {question && (
                                                <CSSTransition
                                                    in={animateQuestion}
                                                    timeout={300}
                                                    className="fade"
                                                    unmountOnExit
                                                    onEnter={()=>setActivateScrollToBottom(true)}
                                                    >
                                                    <ChatMsg sender="Player">
                                                        <ChatBubbleText>{question}</ChatBubbleText>
                                                    </ChatMsg>
                                                </CSSTransition>
                                            )}
                                            {loading ? (
                                                <CSSTransition
                                                    in={animateLoading}
                                                    timeout={300}
                                                    className="fade"
                                                    unmountOnExit
                                                    onEnter={()=>setActivateScrollToBottom(true)}
                                                >
                                                <ChatMsg sender="Coach">
                                                    <ChatBubbleText>...</ChatBubbleText>
                                                </ChatMsg>
                                                </CSSTransition>
                                            ) : (
                                                response && (
                                                    <CSSTransition
                                                        in={animateResponse}
                                                        timeout={300}
                                                        className="fade"
                                                        unmountOnExit
                                                        onEntered={()=>setActivateScrollToBottom(true)}
                                                    >
                                                    <ChatMsg sender="Coach">
                                                        <ChatBubbleText>{response}</ChatBubbleText>
                                                    </ChatMsg>
                                                    </CSSTransition>
                                                )
                                            )}
                                            </Box>
                                            <InputContainer sx={{ position: "sticky", flexDirection: "column",bottom: 0, backgroundColor: "#fff", zIndex: 2 }}>
                                                <form onSubmit={handleSubmit}
                                                        style={{display: "flex", width: "100%"}}>
                                                    <InputField>
                                                        <input
                                                            type="text"
                                                            placeholder={
                                                                question 
                                                                    ? "Type your question here..." 
                                                                    : (exampleQuestion 
                                                                        ? (exampleQuestion === "Academy Intelligence unavailable for this skill" 
                                                                            ? exampleQuestion 
                                                                            : `Eg. ${exampleQuestion}`) 
                                                                        : "")
                                                            }
                                                            value={userPrompt}
                                                            onChange={(e) => setUserPrompt(e.target.value)}
                                                            style={{
                                                                flexGrow: 1,
                                                                border: "none",
                                                                outline: "none",
                                                                background: "none",
                                                                padding: "10px",
                                                                fontSize: "16px"
                                                            }}
                                                            readOnly={exampleQuestion === "Academy Intelligence unavailable for this skill"}
                                                        />
                                                        {exampleQuestion !== "Academy Intelligence unavailable for this skill" && (
                                                            <IconButton type="submit" color="primary">
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                        )}
                                                    </InputField>
                                                </form>
                                                <Box sx={{ paddingTop: "10px", textAlign: "center" }}>
                                                    <Typography variant="body2" sx={{ color: "#000" }}>
                                                        The Academy Intelligence Coach uses AI.{" "}
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                                color: "primary.main"
                                                            }}
                                                            onClick={() => window.open(Links.terms, "_blank")}
                                                        >
                                                            Learn More
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            </InputContainer>
                                        </>
                                    ) : (
                                        // Show text body if the user is not in the ai-coach-pre-release group
                                        <Box
                                            sx={{
                                                marginTop: "-8px",
                                                paddingBottom: "0px",
                                                paddingLeft: "16px",
                                                paddingRight: "16px",
                                                color: theme.custom.darkerPrimaryColor
                                            }}
                                            dangerouslySetInnerHTML={{__html: post.body}}
                                        />
                                    )}
                                    <Box ref={bottomBoxRef}></Box>
                                </Box>
                            </Box>
                            :
                            <Loader/>
                    }
                </>
                </ScreenLayout>
            </Box>
        </>
    )

}

interface MsgProps {
    sender: "Player" | "Coach",
    children: React.ReactElement,
    ref?: any,
}


const ChatMsg = (props: MsgProps): React.ReactElement => {

    return (<>
        {props.sender === "Coach" &&
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                }}
            >
                <CoachAvatar/>
                <ChatBubbleLeft>
                    {props.children}
                </ChatBubbleLeft>
            </Box>
        }
        {props.sender === "Player" &&
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingLeft: "30px",
                    paddingRight: "30px"
                }}
            >
                <ChatBubbleRight>
                    {props.children}
                </ChatBubbleRight>
            </Box>
        }
    </>)
}
