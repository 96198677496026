import axios from 'axios';
import {
    ICreateOrderRequestData,
    ICreatePaymentIntentArguments,
} from "../features/order/orderSlice";
import {AppConfig} from "../config";
import {getIdToken, setIdToken, tokenRequiredForApiRequest} from "./cognito";
import {forceLogoutKey} from "../constants/urlParameters";
import {getFragmentParams} from "../util/url";
import {ErrorCode} from "../constants/error";
import {IViaSignUpRequestData, IViaSignUpResponseData, ViaUserProfile} from "../features/user/userSlice";
import {
    CreateSetupIntentArgs,
    CreateSubscriptionArgs,
    CreateUserSubscriptionResponse, TrialSetup, UserSubscription, UserSubscriptionsResponse
} from "../features/subscriptions/subscriptionsSlice";
import {GetProVideoSecureUrlArgs, ProVideoSUrlResponse} from "../features/academyContent/academyContentSlice";
import {Analytics} from "../lib/analytics";
import {checkIsWhiteLabelled, getItem} from "../util/storage";

const api_host = AppConfig.API_HOST;

export interface S3urlsRequest {
    filename: string
    idToken: string
}

export const api = axios.create({
    baseURL: api_host,
    headers: {
        Accept: "application/json",
    },
});

api.interceptors.request.use(
    async (config) => {

        const token = await getIdToken();
        // console.log("mail - intercept token", token)
        if (config.headers) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.response) {
            if (err.response.status === ErrorCode.unauthorized && !window.location.href.includes(forceLogoutKey)) {
                window.location.href = window.location.href + getFragmentParams(forceLogoutKey, true);
                return Promise.reject(err);
            }

            if (err.response.status === ErrorCode.forbidden && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

export async function createPaymentIntent(args: ICreatePaymentIntentArguments) {
    const response = await api.put('/v1/store/payment/intent', args);
    return response.data;
}
export async function updatePaymentIntent(args: {id: string, source: string, organisation_id: string|undefined, metadata: {order_id: string, receipt_recipient: string}}) {
    const response = await api.put('/v1/store/payment/intent', args);
    return response.data;
}
export async function createOrder(args: ICreateOrderRequestData) {
    const response = await api.post('/v1/store/order', args);
    return response.data;
}

export async function getSubscriptionProducts(categorySlug: string) {
    await setIdToken()
    const response = await api.get('/v1/store/categories/' + categorySlug);
    return response.data;
}

export async function getUserSubscriptions() {
    await tokenRequiredForApiRequest(300)
    const args: string = `?page=1&pageSize=100&organisation_id=${getItem("organisation_id")}`
    const response = await api.get('/v1/store/subscriptions/' + args);
    return response.data as UserSubscriptionsResponse;
}

export async function getProVideoSecureMedia(args: GetProVideoSecureUrlArgs) {
    const response = await api.get(args.surl);
    return {
        media: {...response.data, videoPageId: args.videoPageId},
        status: response.status,
    } as ProVideoSUrlResponse;
}


export async function getIntendedSubscription(uuid: string) {
    await tokenRequiredForApiRequest(300)
    const response = await api.get('/v1/store/subscriptions/' + uuid + '/' );
    return response.data as UserSubscription
}

export async function deleteSubscription(uuid: string) {
    await tokenRequiredForApiRequest(300)
    const response = await api.delete('/v1/store/subscriptions/' + uuid);
    return response.data;
}

export async function createUserSubscription(args: CreateSubscriptionArgs) {
    await tokenRequiredForApiRequest(300)
    const response = await api.post('/v1/store/subscriptions/', args);
    return response.data as CreateUserSubscriptionResponse;
}

export async function createSetupIntent(args: CreateSetupIntentArgs) {
    const response = await api.put('/v1/store/setup/intent', args);
    console.log("setup intent response: ", response)
    return response.data as TrialSetup;
}

export async function userSignUp(args: IViaSignUpRequestData) {
    const response = await api.post('/v1/users/signup', args);
    Analytics.signUp();
    return response.data as IViaSignUpResponseData;
}

export async function updateUserProfile(args: object) {
    const response = await api.patch('/v1/profile', args);
    return response.data as ViaUserProfile;
}

export async function getConsent() {
    const response = await api.get('/v1/consent');
    return response.data;
}

export async function requestConsent(args: {feature: string}[]) {
    const response = await api.put('/v1/consent/request', args);
    return response.data;
}

export async function createAnonAccount(rand16: string) {
    const payload = {"key": rand16}
    const response = await api.post('/v1/accounts/temporary', payload);
    return response.data
}
export async function deleteUserProfile(accessToken: string) {
    console.log("deleting account. Access token: ", accessToken)
    const payload = {access_token: accessToken}
    const response = await api.delete('/v1/profile', {data: payload});
    return response.status;
}

export async function getOrganisationsList() {
    const response = await api.get('/v1/organisations/');
    return response.data;
}

export async function getCoachFeedBackRequests(orgId: string) {
    const orgString = !!orgId && checkIsWhiteLabelled() ? `&organisation_id=${orgId}` : ""
    console.log("orgString", orgString)
    const response = await api.get("/v1/coach-feedback-requests?pageSize=1" + orgString );
    return response.data.results;
}

export async function getS3urls(args: S3urlsRequest) {
    const response = await api.get(
        '/v1/media-upload-url/' + args.filename,
    );
    return response.data;
}

export async function getViaUserProfile() {
    const response = await api.get(
        '/v1/profile',

    );

    return response.data;
}

export async function generateVirtualCoachPrompt(userPrompt:string, clubAbbreviation:string, videoNumber:string, category:string) {
    const payload = {
        prompt: userPrompt,
        club_abbreviation: clubAbbreviation,
        video_number: videoNumber,
        category: category
    };

    try {
        const response = await api.post('/v1/virtual-coach-prompt-gen', payload);
        console.log(response)
        return response.data.response;
    } catch (error) {
        console.error("Error generating virtual coach prompt: ", error);
        return `Sorry, I don't have any data on this video yet. If you want help with this skill, please check back later 
                or submit a Coach Feedback to our academy coaches.`
    }
}

export async function generateVirtualCoachOpeningLines(clubAbbreviation:string, videoNumber:string, category:string) {
    const payload = {
        club_abbreviation: clubAbbreviation,
        video_number: videoNumber,
        category: category
    };

    try {
        const response = await api.post('/v1/virtual-coach-opening-lines', payload);
        console.log(response);
        
        const parsedResponse = JSON.parse(response.data.response);
        
        return parsedResponse;
    } catch (error) {
        console.error("Error generating virtual coach opening lines: ", error);
        return {
            intro_message1: "Sorry, I don't have any data on this video yet.",
            intro_message2: "If you want help with this skill, please check back later or submit a Coach Feedback to our academy coaches.",
            example_question: "Academy Intelligence unavailable for this skill"
        };
    }
}